import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Modal } from '../../../../@domain/modal/modal.enum';
import { ModalService } from '../../../../@domain/modal/modal.service';
import { PlaylistService } from '../../../../@domain/playlist/application/playlist.service';
import { UserService } from '../../../../@domain/user/application/user.service';
import { ModalBodyComponent } from '../../global/modal/modal-body/modal-body.component';
import { ModalFooterComponent } from '../../global/modal/modal-footer/modal-footer.component';
import { ModalHeaderComponent } from '../../global/modal/modal-header/modal-header.component';
import { ModalComponent } from '../../global/modal/modal.component';

@Component({
    selector: 'app-create-playlist-modal',
    imports: [
        FormsModule,
        ModalBodyComponent,
        ModalComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
    ],
    styles: `
        .form-control {
            input,
            textarea {
                background-color: #f3f4f6;
                border: none;
            }
        }

        .dropzone {
            padding: 1rem 3rem;
            border: 3px dashed #e5e7eb;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            a {
                text-decoration: none;
                color: #ff0035;
            }

            span {
                text-align: center;
                color: #6b7280;
                font-size: 14px;
            }

            fa-icon {
                font-size: 50px;
                color: #e5e7eb;
            }
        }

        h4 {
            font-size: 20px;
            font-weight: 700;
        }

        .footer {
            justify-content: space-between;

            .playlist-create {
                padding: 9px 17px !important;
            }

            .cancel {
                padding: 9px 17px !important;
                background-color: transparent;
                color: #1f2937;
            }
        }
    `,
    template: `
        <app-modal [tag]="Modal.CreatePlaylist">
            <app-modal-header modalTitle="Create a new playlist"/>
            <app-modal-body>
                <form>
                    <div class="form-control">
                        <input
                            type="text"
                            name="playlistTitle"
                            placeholder="Playlist title"
                            id="playlistTitle"
                            maxlength="120"
                            [(ngModel)]="playlistTitle"
                        />
                    </div>
                </form>
            </app-modal-body>
            <app-modal-footer>
                <div class="d-flex footer">
                    <button class="btn cancel" (click)="onCloseModal()">Cancel</button>
                    <button class="playlist-create btn btn-primary" (click)="createPlaylist()">Create</button>
                </div>
            </app-modal-footer>
        </app-modal>
    `
})
export class CreatePlaylistModalComponent {
    private userService = inject(UserService);
    private playlistService = inject(PlaylistService);
    private modalService = inject(ModalService);

    public playlistTitle = '';
    public Modal = Modal;

    createPlaylist() {
        this.playlistService.createPlaylist({ title: this.playlistTitle })
            .subscribe(() => {
                this.playlistTitle = '';
                this.onCloseModal();
            });
    }

    onCloseModal(): void {
        this.modalService.close();
    }
}
